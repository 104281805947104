<template>
  <v-container>
    <v-btn class="px-2" @click="goToHomePageHandler">
      <v-icon>mdi-chevron-left</v-icon>
      Go to Home page
    </v-btn>
    <v-row>
      <v-col cols="2" class="d-flex flex-column selectedData mt-16 px-0 pb-0">
        <v-expand-transition>
          <div v-show="!!from" class="my-5">
            <p class="mb-1"><v-icon>mdi-map-marker</v-icon>From</p>
            <h3><v-icon>mdi-ferry</v-icon>{{ from }}</h3>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-show="!!to" class="my-5">
            <p class="mb-1"><v-icon>mdi-map-marker</v-icon>To</p>
            <h3><v-icon>mdi-ferry</v-icon>{{ to }}</h3>
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-show="!!date" class="my-5">
            <p class="mb-1">Ship Departs</p>
            <h3><v-icon>mdi-calendar-month</v-icon>{{ choseDateHandler }}</h3>
          </div>
        </v-expand-transition>

        <!-- <v-expand-transition>
          <div v-show="!!containers.quantity && !!containers.type">
            <h3>
              <v-icon>mdi-archive-outline</v-icon>
              {{ sidebarContainer }}
            </h3>
          </div>
        </v-expand-transition> -->
        <v-expand-transition>
          <div v-show="showClearBtn" class="clearBtn">
            <v-btn depressed outlined @click="clearHandler">
              Clear All
            </v-btn>
          </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="8" class="d-flex flex-wrap justify-center py-0">
        <v-col cols="6" class="d-flex align-end py-0">
          <div class="from-to-input">
            <h4 class="label"><v-icon>mdi-map-marker</v-icon>From</h4>
            <v-autocomplete
              :items="portsList"
              item-text="country"
              dense
              outlined
              :value="countryFrom"
              :rules="[() => !!countryFrom]"
              ref="input-from-country"
              label="Choose a country"
              @change="filterPorts('from', $event, countryFrom)"
            >
            </v-autocomplete>

            <v-autocomplete
              :items="filtredPortsListFrom"
              item-text="port"
              dense
              outlined
              v-model="from"
              :rules="[() => !!from]"
              ref="input-from"
              :error-messages="error"
              label="Choose a port"
            >
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="6" class="d-flex align-end py-0">
          <div class="from-to-input">
            <h4 class="label"><v-icon>mdi-map-marker</v-icon>To</h4>
            <v-autocomplete
              :items="portsList"
              item-text="country"
              dense
              outlined
              ref="input-to-country"
              @change="filterPorts('to', $event, countryTo)"
              label="Choose a country"
              :value="countryTo"
              :rules="[() => !!countryTo]"
            >
            </v-autocomplete>

            <v-autocomplete
              :items="filtredPortsListTo"
              item-text="port"
              :rules="[() => !!to]"
              ref="input-to"
              dense
              outlined
              v-model="to"
              :error-messages="error"
              label="Choose a port"
            >
            </v-autocomplete>
          </div>
        </v-col>

        <v-col cols="8" class="d-flex flex-column align-center py-0">
          <v-input
            :rules="[() => !!date]"
            ref="date"
            label="When you would like the vessel to sail?"
            v-model="date"
            readonly
          ></v-input>
          <v-date-picker
            first-day-of-week="1"
            v-model="date"
            no-title
            :allowed-dates="allowedDates"
          >
          </v-date-picker>
        </v-col>

        <v-col cols="8" class="d-flex align-center flex-wrap py-0 flex-column">
          <!-- <p>Add containers please</p>
          <v-row>
            <v-col cols="7" offset="1">
              <p>Equipment Type</p>
              <v-select
                class="castomSelectror"
                :items="equipmentTypes"
                dense
                outlined
                v-model="containers.type"
                :rules="[() => validateContainerType]"
                ref="input-containers_type"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }} - {{ data.item.size }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.name }} - {{ data.item.size }}
                </template></v-select
              >
            </v-col>
            <v-col cols="3">
              <p>Quantity</p>
              <div class="d-flex">
                <v-text-field
                  type="number"
                  min="0"
                  dense
                  outlined
                  hide-details
                  v-model="containers.quantity"
                  :rules="[() => !!containers.quantity]"
                  ref="input-containers_quantity"
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row> -->
          <v-btn depressed outlined @click="submit" class="mt-10">
            Next Step
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TOAST_TYPE } from '../../utils/constants';
export default {
  name: 'NewBooking',
  data: () => ({
    date: null,
    from: null,
    to: null,
    portsList: [],
    error: [],
    filtredPortsListFrom: [],
    filtredPortsListTo: [],
    countryFrom: null,
    countryTo: null
  }),
  async mounted() {
    this.portsList = await this.loadPortsList();
    if (!this.portsList.length) {
      this.setToast({
        message: 'AvailablePortsNotExist',
        type: TOAST_TYPE.ERROR
      });
    }
    if (Object.keys(this.getInstructionData).length) {
      this.countryFrom = this.getInstructionData.loadingPort.country;
      this.countryTo = this.getInstructionData.dischargePort.country;
      this.filterPorts('from', this.countryFrom);
      this.filterPorts('to', this.countryTo);
      this.from = this.getInstructionData.loadingPort.port;
      this.to = this.getInstructionData.dischargePort.port;
      this.date = this.getInstructionData.departure_date.slice(0, 10);
    }
  },

  methods: {
    fromToInputsHandler() {
      if (this.from === this.to && this.to != null) {
        this.error.push("can't be the same");
      } else {
        this.error = [];
      }
    },
    submit() {
      Object.keys(this.$refs).forEach((f) => {
        this.$refs[f].validate(true);
      });

      if (this.readyToConfirm) {
        this.setInstructionForm(this.selectedData);
        this.$router.push('/instruction');
      } else {
        this.setToast({
          message: 'EmptyRequiredField',
          type: TOAST_TYPE.ERROR
        });
      }
    },
    ...mapActions({
      loadPortsList: 'newBooking/loadPortsList',
      setInstructionForm: 'instructionForm/setInstructionForm',
      clearInstructionForm: 'instructionForm/clearInstructionForm',
      setToast: 'toast/setToast'
    }),
    allowedDates: (val) => {
      return Date.parse(val) >= new Date().setHours(0);
    },
    clearHandler() {
      this.date = null;
      Object.keys(this.$refs).forEach((f) => {
        this.$refs[f].reset();
      });
      this.filtredPortsListFrom = [];
      this.filtredPortsListTo = [];
      this.clearInstructionForm();
    },
    filterPorts(direction, event, item) {
      //if item exist, find previously selected country
      if (item) {
        const prevCountry = this.portsList.find((portItem) => {
          return portItem.country === item;
        });
        //make prevCountry enable
        prevCountry.disabled = false;
      }

      const capitalazeDirection =
        direction[0].toUpperCase() + direction.substring(1);

      this[`filtredPortsList${capitalazeDirection}`] = JSON.parse(
        JSON.stringify(this.portsList.filter((port) => port.country === event))
      );
      //if there is only one port in the selected country, disable that country
      if (this[`filtredPortsList${capitalazeDirection}`].length === 1) {
        const disablerCountry = this.portsList.find((portItem) => {
          return portItem.country === event;
        });
        disablerCountry.disabled = true;
      }

      this[`country${capitalazeDirection}`] = event;
      direction === 'from' ? (this.from = null) : (this.to = null);
    },
    goToHomePageHandler() {
      if (this.$route.params.id) {
        this.clearInstructionForm();
      }
      this.$router.push('/allInstructions');
    }
  },
  watch: {
    from() {
      this.fromToInputsHandler();
    },

    to() {
      this.fromToInputsHandler();
    }
  },
  computed: {
    ...mapGetters('instructionForm', ['getInstructionData']),
    readyToConfirm() {
      return !!this.from && !!this.to && this.from != this.to && !!this.date;
    },
    selectedData() {
      return {
        loadingPort: this.portsList.find((obj) => {
          return obj.port === this.from;
        }),
        dischargePort: this.portsList.find((obj) => {
          return obj.port === this.to;
        }),
        departure_date: new Date(this.date).toISOString()
      };
    },
    showClearBtn() {
      return !!this.from || !!this.to || !!this.date;
    },
    choseDateHandler() {
      const newDate = new Date(this.date).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
      const formatedDate = newDate.replace(',', '').split(' ');
      return [formatedDate[1], formatedDate[0], formatedDate[2]].join(' ');
    }
  }
};
</script>

<style scoped>
@import './newBooking.module.css';
</style>
